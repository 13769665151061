
.stepContainer {
    margin-bottom: 4px;
    /* width: 100%;
    margin: auto; */
}
.threestepbackground {
    background-color: #0d6dfdbb;
    border-radius: 150% 0 200% 0;
}
.media {
    background-image: url(../../images/work-tv.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;

}

@media only screen and (max-width: 900px) {
    .switchorder div:nth-of-type(1) {
        order:1;
        padding-top: 10px;
    }
    .stepContainer {
       margin-top: 50px; 
       width: auto;
       text-align: center;
    }
    .media {
        height: auto;
    }

}
